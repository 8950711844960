{
  "gameClient": {
    "observerServerIp": "192.64.174.163",
    "observerServerPort": 80,
    "running": true,
    "serverIp": "192.64.173.83",
    "serverPort": 5327,
    "visible": false
  },
  "gameData": {
    "gameId": 4337612090,
    "gameName": "",
    "isCustomGame": false,
    "password": "",
    "playerChampionSelections": [
      {
        "championId": 15,
        "selectedSkinIndex": 4,
        "spell1Id": 4,
        "spell2Id": 7,
        "summonerInternalName": "doublelift"
      },
      {
        "championId": 62,
        "selectedSkinIndex": 6,
        "spell1Id": 11,
        "spell2Id": 4,
        "summonerInternalName": "crewman44"
      },
      {
        "championId": 38,
        "selectedSkinIndex": 0,
        "spell1Id": 14,
        "spell2Id": 4,
        "summonerInternalName": "yooner"
      },
      {
        "championId": 875,
        "selectedSkinIndex": 9,
        "spell1Id": 4,
        "spell2Id": 14,
        "summonerInternalName": "tooversatile"
      },
      {
        "championId": 412,
        "selectedSkinIndex": 1,
        "spell1Id": 4,
        "spell2Id": 3,
        "summonerInternalName": "rió"
      },
      {
        "championId": 161,
        "selectedSkinIndex": 2,
        "spell1Id": 21,
        "spell2Id": 4,
        "summonerInternalName": "over9000asians"
      },
      {
        "championId": 517,
        "selectedSkinIndex": 11,
        "spell1Id": 4,
        "spell2Id": 14,
        "summonerInternalName": "hijackntriscuit"
      },
      {
        "championId": 57,
        "selectedSkinIndex": 5,
        "spell1Id": 4,
        "spell2Id": 12,
        "summonerInternalName": "xpayne"
      },
      {
        "championId": 40,
        "selectedSkinIndex": 36,
        "spell1Id": 3,
        "spell2Id": 4,
        "summonerInternalName": "mykayla"
      },
      {
        "championId": 141,
        "selectedSkinIndex": 0,
        "spell1Id": 4,
        "spell2Id": 11,
        "summonerInternalName": "hewy"
      }
    ],
    "queue": {
      "allowablePremadeSizes": [
        1,
        2
      ],
      "areFreeChampionsAllowed": false,
      "assetMutator": "",
      "category": "PvP",
      "championsRequiredToPlay": 20,
      "description": "ARAM",
      "detailedDescription": "",
      "gameMode": "CLASSIC",
      "gameTypeConfig": {
        "advancedLearningQuests": false,
        "allowTrades": true,
        "banMode": "StandardBanStrategy",
        "banTimerDuration": 38,
        "battleBoost": false,
        "crossTeamChampionPool": false,
        "deathMatch": false,
        "doNotRemove": false,
        "duplicatePick": false,
        "exclusivePick": true,
        "id": 18,
        "learningQuests": false,
        "mainPickTimerDuration": 0,
        "maxAllowableBans": 6,
        "name": "GAME_CFG_TEAM_BUILDER_DRAFT",
        "onboardCoopBeginner": false,
        "pickMode": "TeamBuilderDraftPickStrategy",
        "postPickTimerDuration": 33,
        "reroll": false,
        "teamChampionPool": false
      },
      "id": 420,
      "isRanked": true,
      "isTeamBuilderManaged": true,
      "isTeamOnly": false,
      "lastToggledOffTime": 1654677001568,
      "lastToggledOnTime": 1654687155547,
      "mapId": 12,
      "maxLevel": 0,
      "maxSummonerLevelForFirstWinOfTheDay": 0,
      "maximumP laning tags dataarticipantListSize": 2,
      "minLevel": 30,
      "minimumParticipantListSize": 1,
      "name": "ARAM",
      "numPlayersPerTeam": 5,
      "queueAvailability": "Available",
      "queueRewards": {
        "isChampionPointsEnabled": true,
        "isIpEnabled": true,
        "isXpEnabled": true,
        "partySizeIpRewards": []
      },
      "removalFromGameAllowed": false,
      "removalFromGameDelayMinutes": 0,
      "shortName": "Ranked Solo/Duo",
      "showPositionSelector": true,
      "spectatorEnabled": true,
      "type": "RANKED_SOLO_5x5"
    },
    "spectatorsAllowed": false,
    "teamOne": [
      {
        "accountId": 214355928,
        "adjustmentFlags": 0,
        "botDifficulty": "NONE",
        "clientInSynch": true,
        "gameCustomization": {
          "GoldenSpatulaClub": "{\"Rewards\":[]}",
          "Regalia": "{\"crest\":{\"displayType\":\"rank\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
          "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.264},\"402503\":{\"l\":2,\"p\":0.221},\"402500\":{\"l\":3,\"p\":0.173},\"203201\":{\"l\":0,\"p\":1.0},\"402403\":{\"l\":3,\"p\":0.065},\"504002\":{\"l\":6,\"p\":0.087},\"203301\":{\"l\":1,\"p\":0.285},\"2022008\":{\"l\":1,\"p\":0.209},\"401102\":{\"l\":7,\"p\":0.004},\"505006\":{\"l\":7,\"p\":0.217},\"401103\":{\"l\":6,\"p\":0.118},\"2022004\":{\"l\":5,\"p\":0.101},\"501004\":{\"l\":7,\"p\":0.019},\"501001\":{\"l\":6,\"p\":0.068},\"600006\":{\"l\":8,\"p\":0.012},\"501011\":{\"l\":5,\"p\":0.028},\"301106\":{\"l\":0,\"p\":1.0},\"510000\":{\"l\":6,\"p\":0.04},\"2022018\":{\"l\":7,\"p\":0.063}},\"selected\":[505006,600006,401102],\"title\":{\"challengeId\":510006,\"level\":4,\"itemId\":51000603}}",
          "championOwned": "true",
          "perks": "{\"perkIds\":[8369,8304,8345,8347,8226,8237,5008,5008,5002],\"perkStyle\":8300,\"perkSubStyle\":8200}",
          "ranked": "{\"isRankedQueue\":true,\"tier\":\"GOLD\",\"rank\":\"I\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":true,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
          "statstones": "{\"statstones\":[{\"contentId\":\"1437c49d-2abf-4428-8d01-b0f86c36c47f\",\"payload\":{\"currentValue\":641,\"isShowcased\":true,\"maxDelta\":6,\"disabled\":false}},{\"contentId\":\"446bc95b-ecf7-4029-8842-dffd185f9a70\",\"payload\":{\"currentValue\":6372,\"isShowcased\":true,\"maxDelta\":65,\"disabled\":false}},{\"contentId\":\"70829007-25ea-4df2-913d-758e28363a42\",\"payload\":{\"currentValue\":1161,\"isShowcased\":true,\"maxDelta\":13,\"disabled\":false}}]}",
          "summonerBanner": "{\"flag\":{\"level\":\"1\",\"theme\":\"Ionia\"},\"frame\":{\"level\":\"3\"}}",
          "summonerEmotes": "{\"summonerEmoteIds\":[3758,3756,3357,3795,1030,3492,3357,3481,3680]}",
          "summonerTrophy": "null",
          "vintageSkin": "{\"isVintageSkin\":false}",
          "wardSkin": "{\"wardSkinId\":136}"
        },
        "index": 0,
        "lastSelectedSkinIndex": 2,
        "locale": null,
        "minor": false,
        "originalAccountNumber": 0,
        "originalPlatformId": null,
        "partnerId": "",
        "pickMode": 1,
        "pickTurn": 0,
        "profileIconId": 4561,
        "puuid": "afa2d8d3-8a84-5958-ab25-1cd3029eaff4",
        "queueRating": 0,
        "rankedTeamGuest": false,
        "selectedPosition": "UTILITY",
        "selectedRole": "UTILITY.PRIMARY.UTILITY.MIDDLE",
        "summonerId": 51555015,
        "summonerInternalName": "over9000asians",
        "summonerName": "over9000asians",
        "teamOwner": false,
        "teamParticipantId": 1,
        "teamRating": 0,
        "timeAddedToQueue": 1654888932561,
        "timeChampionSelectStart": 1654888951317,
        "timeGameCreated": 1654889181005,
        "timeMatchmakingStart": 1654888932561,
        "voterRating": 0
      },
      {
        "accountId": 207109597,
        "adjustmentFlags": 0,
        "botDifficulty": "NONE",
        "clientInSynch": true,
        "gameCustomization": {
          "GoldenSpatulaClub": "{\"Rewards\":[]}",
          "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
          "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.264},\"402503\":{\"l\":4,\"p\":0.024},\"402500\":{\"l\":4,\"p\":0.04},\"203201\":{\"l\":5,\"p\":0.005},\"402403\":{\"l\":2,\"p\":0.138},\"504002\":{\"l\":6,\"p\":0.087},\"203301\":{\"l\":3,\"p\":0.048},\"2022008\":{\"l\":2,\"p\":0.123},\"401102\":{\"l\":7,\"p\":0.008},\"505006\":{\"l\":7,\"p\":0.217},\"401103\":{\"l\":8,\"p\":0.002},\"2022004\":{\"l\":5,\"p\":0.101},\"501004\":{\"l\":0,\"p\":1.0},\"501001\":{\"l\":0,\"p\":1.0},\"600006\":{\"l\":8,\"p\":0.002},\"501011\":{\"l\":0,\"p\":1.0},\"301106\":{\"l\":6,\"p\":0.068},\"510000\":{\"l\":4,\"p\":0.267},\"2022018\":{\"l\":7,\"p\":0.01}},\"selected\":[2022018,401103,203201],\"title\":{\"challengeId\":203201,\"level\":5,\"itemId\":20320104}}",
          "championOwned": "true",
          "perks": "{\"perkIds\":[8010,9111,9104,8014,8304,8347,5005,5008,5002],\"perkStyle\":8000,\"perkSubStyle\":8300}",
          "ranked": "{\"isRankedQueue\":true,\"tier\":\"GOLD\",\"rank\":\"I\",\"previousSeasonAchievedTier\":\"PLATINUM\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":true,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
          "statstones": "{\"statstones\":[]}",
          "summonerBanner": "{\"flag\":{\"level\":\"3\",\"theme\":\"Ionia\"},\"frame\":{\"level\":\"4\"}}",
          "summonerEmotes": "{\"summonerEmoteIds\":[3155,3170,1506,3358,3336,3145,3199,3335,3134]}",
          "summonerTrophy": "null",
          "vintageSkin": "{\"isVintageSkin\":false}",
          "wardSkin": "{\"wardSkinId\":91}"
        },
        "index": 0,
        "lastSelectedSkinIndex": 6,
        "locale": null,
        "minor": false,
        "originalAccountNumber": 0,
        "originalPlatformId": null,
        "partnerId": "",
        "pickMode": 1,
        "pickTurn": 0,
        "profileIconId": 5143,
        "puuid": "e9e88091-098d-5ad0-8b70-a3d3886a9f44",
        "queueRating": 0,
        "rankedTeamGuest": false,
        "selectedPosition": "JUNGLE",
        "selectedRole": "JUNGLE.PRIMARY.JUNGLE.BOTTOM",
        "summonerId": 44208094,
        "summonerInternalName": "crewman44",
        "summonerName": "Crewman44",
        "teamOwner": false,
        "teamParticipantId": 2,
        "teamRating": 0,
        "timeAddedToQueue": 1654888921657,
        "timeChampionSelectStart": 1654888951317,
        "timeGameCreated": 1654889181005,
        "timeMatchmakingStart": 1654888921657,
        "voterRating": 0
      },
      {
        "accountId": 208690360,
        "adjustmentFlags": 0,
        "botDifficulty": "NONE",
        "clientInSynch": true,
        "gameCustomization": {
          "GoldenSpatulaClub": "{\"Rewards\":[]}",
          "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
          "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.264},\"402503\":{\"l\":2,\"p\":0.221},\"402500\":{\"l\":2,\"p\":0.273},\"203201\":{\"l\":0,\"p\":1.0},\"402403\":{\"l\":1,\"p\":0.253},\"504002\":{\"l\":7,\"p\":0.008},\"203301\":{\"l\":2,\"p\":0.155},\"2022008\":{\"l\":2,\"p\":0.123},\"401102\":{\"l\":7,\"p\":0.001},\"505006\":{\"l\":7,\"p\":0.217},\"401103\":{\"l\":6,\"p\":0.118},\"2022004\":{\"l\":0,\"p\":1.0},\"501004\":{\"l\":8,\"p\":0.009},\"501001\":{\"l\":6,\"p\":0.068},\"600006\":{\"l\":8,\"p\":0.007},\"501011\":{\"l\":0,\"p\":1.0},\"301106\":{\"l\":0,\"p\":1.0},\"510000\":{\"l\":7,\"p\":0.009},\"2022018\":{\"l\":0,\"p\":1.0}},\"selected\":[501004,510000,504002],\"title\":{\"challengeId\":101206,\"level\":2,\"itemId\":10120601}}",
          "championOwned": "true",
          "perks": "{\"perkIds\":[8010,8009,9105,8299,8345,8347,5008,5008,5003],\"perkStyle\":8000,\"perkSubStyle\":8300}",
          "ranked": "{\"isRankedQueue\":true,\"tier\":\"PLATINUM\",\"rank\":\"IV\",\"previousSeasonAchievedTier\":\"PLATINUM\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":true,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
          "statstones": "{\"statstones\":[{\"contentId\":\"dcc8a204-dab3-4faf-88a3-ed7bd98e4ed7\",\"payload\":{\"currentValue\":7318,\"isShowcased\":false,\"maxDelta\":49,\"disabled\":false}},{\"contentId\":\"1ae71e2d-2a5a-4508-9613-88dab84b3d20\",\"payload\":{\"currentValue\":377,\"isShowcased\":false,\"maxDelta\":6,\"disabled\":false}},{\"contentId\":\"a945cd9d-b6d8-4af7-9016-e94cc8a34ac2\",\"payload\":{\"currentValue\":980,\"isShowcased\":false,\"maxDelta\":10,\"disabled\":false}},{\"contentId\":\"1ed7ad99-0d32-4b9b-b9f6-922874962220\",\"payload\":{\"currentValue\":2290159,\"isShowcased\":true,\"maxDelta\":22353,\"disabled\":false}},{\"contentId\":\"91a7968c-4299-4e30-b1b0-fa7bc199b348\",\"payload\":{\"currentValue\":28857,\"isShowcased\":true,\"maxDelta\":186,\"disabled\":false}},{\"contentId\":\"464ac779-df62-4e03-b3f9-562dd0d433d8\",\"payload\":{\"currentValue\":477,\"isShowcased\":true,\"maxDelta\":5,\"disabled\":false}}]}",
          "summonerBanner": "{\"flag\":{\"level\":\"1\",\"theme\":\"Pilot\"},\"frame\":{\"level\":\"1\"}}",
          "summonerEmotes": "{\"summonerEmoteIds\":[3173,3356,3164,3648,1478,3430,1498,3278,3478]}",
          "summonerTrophy": "null",
          "vintageSkin": "{\"isVintageSkin\":false}",
          "wardSkin": "{\"wardSkinId\":102}"
        },
        "index": 0,
        "lastSelectedSkinIndex": 11,
        "locale": null,
        "minor": false,
        "originalAccountNumber": 0,
        "originalPlatformId": null,
        "partnerId": "",
        "pickMode": 1,
        "pickTurn": 0,
        "profileIconId": 5331,
        "puuid": "37b24832-0836-5ecc-bdb2-655538abce9f",
        "queueRating": 0,
        "rankedTeamGuest": false,
        "selectedPosition": "MIDDLE",
        "selectedRole": "MIDDLE.PRIMARY.MIDDLE.JUNGLE",
        "summonerId": 45811702,
        "summonerInternalName": "hijackntriscuit",
        "summonerName": "Hijackn Triscuit",
        "teamOwner": false,
        "teamParticipantId": 3,
        "teamRating": 0,
        "timeAddedToQueue": 1654888857478,
        "timeChampionSelectStart": 1654888951317,
        "timeGameCreated": 1654889181005,
        "timeMatchmakingStart": 1654888857478,
        "voterRating": 0
      },
      {
        "accountId": 226652747,
        "adjustmentFlags": 0,
        "botDifficulty": "NONE",
        "clientInSynch": true,
        "gameCustomization": {
          "GoldenSpatulaClub": "{\"Rewards\":[]}",
          "Regalia": "{\"crest\":{\"displayType\":\"rank\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
          "challenges": "{\"challenges\":{\"0\":{\"l\":4,\"p\":0.016},\"402503\":{\"l\":3,\"p\":0.111},\"402500\":{\"l\":4,\"p\":0.04},\"203201\":{\"l\":0,\"p\":1.0},\"402403\":{\"l\":4,\"p\":0.025},\"504002\":{\"l\":7,\"p\":0.008},\"203301\":{\"l\":1,\"p\":0.285},\"2022008\":{\"l\":0,\"p\":1.0},\"401102\":{\"l\":6,\"p\":0.112},\"505006\":{\"l\":7,\"p\":0.217},\"401103\":{\"l\":7,\"p\":0.008},\"2022004\":{\"l\":6,\"p\":0.035},\"501004\":{\"l\":9,\"p\":0.002},\"501001\":{\"l\":7,\"p\":0.021},\"600006\":{\"l\":8,\"p\":0.002},\"501011\":{\"l\":0,\"p\":1.0},\"301106\":{\"l\":6,\"p\":0.068},\"510000\":{\"l\":6,\"p\":0.04},\"2022018\":{\"l\":8,\"p\":0.01}},\"selected\":[402503,402500,402403],\"title\":{\"challengeId\":101206,\"level\":2,\"itemId\":10120601}}",
          "championOwned": "true",
          "perks": "{\"perkIds\":[8005,8009,9103,8017,8233,8236,5005,5008,5002],\"perkStyle\":8000,\"perkSubStyle\":8200}",
          "ranked": "{\"isRankedQueue\":true,\"tier\":\"GOLD\",\"rank\":\"I\",\"previousSeasonAchievedTier\":\"PLATINUM\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":true,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
          "statstones": "{\"statstones\":[{\"contentId\":\"4e160f88-bec7-4349-8d18-589111345239\",\"payload\":{\"currentValue\":5290,\"isShowcased\":false,\"maxDelta\":42,\"disabled\":false}},{\"contentId\":\"3eed0852-9c16-485e-8f62-9c486765a385\",\"payload\":{\"currentValue\":538,\"isShowcased\":false,\"maxDelta\":6,\"disabled\":false}},{\"contentId\":\"cd9ad8bf-68ac-4647-a1f0-39bdfe0b77c6\",\"payload\":{\"currentValue\":947,\"isShowcased\":false,\"maxDelta\":12,\"disabled\":false}},{\"contentId\":\"1337c15b-b889-4774-9c50-5d55a52cd24f\",\"payload\":{\"currentValue\":1106,\"isShowcased\":true,\"maxDelta\":10,\"disabled\":false}},{\"contentId\":\"e15d7668-7b80-49b2-9832-07bbe9aa39b8\",\"payload\":{\"currentValue\":124086442,\"isShowcased\":true,\"maxDelta\":653596,\"disabled\":false}},{\"contentId\":\"20adc76d-6cf1-4261-9780-836402830c89\",\"payload\":{\"currentValue\":553,\"isShowcased\":true,\"maxDelta\":10,\"disabled\":false}}]}",
          "summonerBanner": "{\"flag\":{\"level\":\"1\",\"theme\":\"Piltover\"},\"frame\":{\"level\":\"1\"}}",
          "summonerEmotes": "{\"summonerEmoteIds\":[3901,1459,3897,3829,1499,3829,3829,0,0]}",
          "summonerTrophy": "null",
          "vintageSkin": "{\"isVintageSkin\":true}",
          "wardSkin": "{\"wardSkinId\":123}"
        },
        "index": 0,
        "lastSelectedSkinIndex": 36,
        "locale": null,
        "minor": false,
        "originalAccountNumber": 0,
        "originalPlatformId": null,
        "partnerId": "",
        "pickMode": 1,
        "pickTurn": 0,
        "profileIconId": 5389,
        "puuid": "ec2885af-a151-57bd-8191-024ab2b7de3f",
        "queueRating": 0,
        "rankedTeamGuest": false,
        "selectedPosition": "BOTTOM",
        "selectedRole": "BOTTOM.PRIMARY.BOTTOM.MIDDLE",
        "summonerId": 64230384,
        "summonerInternalName": "mykayla",
        "summonerName": "mykayla",
        "teamOwner": false,
        "teamParticipantId": 4,
        "teamRating": 0,
        "timeAddedToQueue": 1654888909558,
        "timeChampionSelectStart": 1654888951317,
        "timeGameCreated": 1654889181005,
        "timeMatchmakingStart": 1654888909558,
        "voterRating": 0
      },
      {
        "accountId": 210076797,
        "adjustmentFlags": 0,
        "botDifficulty": "NONE",
        "clientInSynch": true,
        "gameCustomization": {
          "GoldenSpatulaClub": "{\"Rewards\":[]}",
          "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
          "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.264},\"402503\":{\"l\":1,\"p\":0.363},\"402500\":{\"l\":2,\"p\":0.273},\"203201\":{\"l\":1,\"p\":0.156},\"402403\":{\"l\":0,\"p\":1.0},\"504002\":{\"l\":5,\"p\":0.265},\"203301\":{\"l\":2,\"p\":0.155},\"2022008\":{\"l\":0,\"p\":1.0},\"401102\":{\"l\":6,\"p\":0.112},\"505006\":{\"l\":7,\"p\":0.217},\"401103\":{\"l\":6,\"p\":0.118},\"2022004\":{\"l\":0,\"p\":1.0},\"501004\":{\"l\":7,\"p\":0.004},\"501001\":{\"l\":5,\"p\":0.143},\"600006\":{\"l\":6,\"p\":0.157},\"501011\":{\"l\":0,\"p\":1.0},\"301106\":{\"l\":0,\"p\":1.0},\"510000\":{\"l\":5,\"p\":0.145},\"2022018\":{\"l\":4,\"p\":0.129}},\"selected\":[]}",
          "championOwned": "true",
          "perks": "{\"perkIds\":[8437,8401,8444,8453,9111,9105,5008,5002,5002],\"perkStyle\":8400,\"perkSubStyle\":8000}",
          "ranked": "{\"isRankedQueue\":true,\"tier\":\"GOLD\",\"rank\":\"I\",\"previousSeasonAchievedTier\":\"GOLD\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":true,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
          "statstones": "{\"statstones\":[{\"contentId\":\"c456e15a-5923-4f7b-8c7b-a8adc3ca873d\",\"payload\":{\"currentValue\":2762,\"isShowcased\":true,\"maxDelta\":14,\"disabled\":false}},{\"contentId\":\"d33fdf33-c11d-4c21-819c-bbc0734b99ff\",\"payload\":{\"currentValue\":10008,\"isShowcased\":true,\"maxDelta\":39,\"disabled\":false}},{\"contentId\":\"d5f5368d-904a-4b0a-9490-f4ee1f1a2a5d\",\"payload\":{\"currentValue\":640,\"isShowcased\":true,\"maxDelta\":6,\"disabled\":false}}]}",
          "summonerEmotes": "{\"summonerEmoteIds\":[3153,3132,3355,3257,3412,3622,3132,3227,1462]}",
          "summonerTrophy": "null",
          "vintageSkin": "{\"isVintageSkin\":true}",
          "wardSkin": "{\"wardSkinId\":207}"
        },
        "index": 0,
        "lastSelectedSkinIndex": 9,
        "locale": null,
        "minor": false,
        "originalAccountNumber": 0,
        "originalPlatformId": null,
        "partnerId": "",
        "pickMode": 1,
        "pickTurn": 0,
        "profileIconId": 3369,
        "puuid": "99a7ba64-3c5e-5b8d-8f8b-211a1720494b",
        "queueRating": 0,
        "rankedTeamGuest": false,
        "selectedPosition": "TOP",
        "selectedRole": "TOP.PRIMARY.TOP.MIDDLE",
        "summonerId": 47269778,
        "summonerInternalName": "tooversatile",
        "summonerName": "Too Versatile",
        "teamOwner": false,
        "teamParticipantId": 5,
        "teamRating": 0,
        "timeAddedToQueue": 1654888872032,
        "timeChampionSelectStart": 1654888951317,
        "timeGameCreated": 1654889181005,
        "timeMatchmakingStart": 1654888872032,
        "voterRating": 0
      }
    ],
    "teamTwo": [
      {
        "accountId": 217611589,
        "adjustmentFlags": 0,
        "botDifficulty": "NONE",
        "clientInSynch": true,
        "gameCustomization": {
          "GoldenSpatulaClub": "{\"Rewards\":[]}",
          "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
          "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.264},\"402503\":{\"l\":3,\"p\":0.111},\"402500\":{\"l\":3,\"p\":0.173},\"203201\":{\"l\":0,\"p\":1.0},\"402403\":{\"l\":2,\"p\":0.138},\"504002\":{\"l\":5,\"p\":0.265},\"203301\":{\"l\":3,\"p\":0.048},\"2022008\":{\"l\":3,\"p\":0.083},\"401102\":{\"l\":6,\"p\":0.112},\"505006\":{\"l\":7,\"p\":0.217},\"401103\":{\"l\":6,\"p\":0.118},\"2022004\":{\"l\":0,\"p\":1.0},\"501004\":{\"l\":4,\"p\":0.157},\"501001\":{\"l\":3,\"p\":0.243},\"600006\":{\"l\":7,\"p\":0.001},\"501011\":{\"l\":0,\"p\":1.0},\"301106\":{\"l\":0,\"p\":1.0},\"510000\":{\"l\":4,\"p\":0.267},\"2022018\":{\"l\":1,\"p\":0.317}},\"selected\":[]}",
          "championOwned": "true",
          "perks": "{\"perkIds\":[8112,8139,8138,8106,8009,8014,5008,5008,5003],\"perkStyle\":8100,\"perkSubStyle\":8000}",
          "ranked": "{\"isRankedQueue\":true,\"tier\":\"PLATINUM\",\"rank\":\"IV\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":true,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
          "statstones": "{\"statstones\":[{\"contentId\":\"701415ca-e0a9-48d3-9f82-91431a8f65b5\",\"payload\":{\"currentValue\":56370,\"isShowcased\":true,\"maxDelta\":12788,\"disabled\":false}},{\"contentId\":\"7adb39ce-936d-4098-8b43-2d3ec3197fff\",\"payload\":{\"currentValue\":59,\"isShowcased\":true,\"maxDelta\":8,\"disabled\":false}},{\"contentId\":\"88429aa7-0be1-4cb5-8c6d-a6a084953773\",\"payload\":{\"currentValue\":1,\"isShowcased\":true,\"maxDelta\":1,\"disabled\":false}}]}",
          "summonerBanner": "{\"flag\":{\"level\":\"1\",\"theme\":\"Demacia\"},\"frame\":{\"level\":\"1\"}}",
          "summonerEmotes": "{\"summonerEmoteIds\":[3335,0,3169,3102,1459,3147,3001,998,3102]}",
          "summonerTrophy": "null",
          "vintageSkin": "{\"isVintageSkin\":false}",
          "wardSkin": "{\"wardSkinId\":40}"
        },
        "index": 0,
        "lastSelectedSkinIndex": 0,
        "locale": null,
        "minor": false,
        "originalAccountNumber": 0,
        "originalPlatformId": null,
        "partnerId": "",
        "pickMode": 1,
        "pickTurn": 0,
        "profileIconId": 657,
        "puuid": "02795309-5e2f-5703-a17e-6cb187e1aafc",
        "queueRating": 0,
        "rankedTeamGuest": false,
        "selectedPosition": "MIDDLE",
        "selectedRole": "MIDDLE.PRIMARY.MIDDLE.BOTTOM",
        "summonerId": 55357747,
        "summonerInternalName": "yooner",
        "summonerName": "yooner",
        "teamOwner": false,
        "teamParticipantId": 6,
        "teamRating": 0,
        "timeAddedToQueue": 1654888920370,
        "timeChampionSelectStart": 1654888951317,
        "timeGameCreated": 1654889181005,
        "timeMatchmakingStart": 1654888920370,
        "voterRating": 0
      },
      {
        "accountId": 2841194811319456,
        "adjustmentFlags": 0,
        "botDifficulty": "NONE",
        "clientInSynch": true,
        "gameCustomization": {
          "GoldenSpatulaClub": "{\"Rewards\":[]}",
          "Regalia": "{\"crest\":{\"displayType\":\"rank\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
          "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.264},\"402503\":{\"l\":3,\"p\":0.111},\"402500\":{\"l\":3,\"p\":0.173},\"203201\":{\"l\":4,\"p\":0.037},\"402403\":{\"l\":3,\"p\":0.065},\"504002\":{\"l\":2,\"p\":0.636},\"203301\":{\"l\":2,\"p\":0.155},\"2022008\":{\"l\":5,\"p\":0.022},\"401102\":{\"l\":4,\"p\":0.488},\"505006\":{\"l\":3,\"p\":0.649},\"401103\":{\"l\":4,\"p\":0.48},\"2022004\":{\"l\":5,\"p\":0.101},\"501004\":{\"l\":0,\"p\":1.0},\"501001\":{\"l\":0,\"p\":1.0},\"600006\":{\"l\":4,\"p\":0.504},\"501011\":{\"l\":0,\"p\":1.0},\"301106\":{\"l\":6,\"p\":0.068},\"510000\":{\"l\":1,\"p\":0.653},\"2022018\":{\"l\":8,\"p\":0.011}},\"selected\":[301106,2022004,2022018],\"title\":{\"challengeId\":301101,\"level\":3,\"itemId\":30110102}}",
          "championOwned": "true",
          "perks": "{\"perkIds\":[8010,9111,9105,8299,8453,8429,5008,5008,5002],\"perkStyle\":8000,\"perkSubStyle\":8400}",
          "ranked": "{\"isRankedQueue\":true,\"tier\":\"GOLD\",\"rank\":\"I\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":true,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
          "statstones": "{\"statstones\":[]}",
          "summonerEmotes": "{\"summonerEmoteIds\":[1459,3176,3812,3236,3237,0,0,3810,0]}",
          "summonerTrophy": "null",
          "vintageSkin": "{\"isVintageSkin\":false}",
          "wardSkin": "{\"wardSkinId\":0}"
        },
        "index": 0,
        "lastSelectedSkinIndex": 0,
        "locale": null,
        "minor": false,
        "originalAccountNumber": 0,
        "originalPlatformId": null,
        "partnerId": "",
        "pickMode": 1,
        "pickTurn": 0,
        "profileIconId": 5067,
        "puuid": "79189b88-c3c6-5df1-b7b2-120d81d8974e",
        "queueRating": 0,
        "rankedTeamGuest": false,
        "selectedPosition": "JUNGLE",
        "selectedRole": "JUNGLE.PRIMARY.JUNGLE.MIDDLE",
        "summonerId": 2841194811319456,
        "summonerInternalName": "hewy",
        "summonerName": "HEWY",
        "teamOwner": false,
        "teamParticipantId": 7,
        "teamRating": 0,
        "timeAddedToQueue": 1654888939568,
        "timeChampionSelectStart": 1654888951317,
        "timeGameCreated": 1654889181005,
        "timeMatchmakingStart": 1654888939568,
        "voterRating": 0
      },
      {
        "accountId": 228389044,
        "adjustmentFlags": 0,
        "botDifficulty": "NONE",
        "clientInSynch": true,
        "gameCustomization": {
          "GoldenSpatulaClub": "{\"Rewards\":[]}",
          "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
          "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.264},\"402503\":{\"l\":4,\"p\":0.024},\"402500\":{\"l\":4,\"p\":0.04},\"203201\":{\"l\":4,\"p\":0.037},\"402403\":{\"l\":4,\"p\":0.025},\"504002\":{\"l\":5,\"p\":0.265},\"203301\":{\"l\":3,\"p\":0.048},\"2022008\":{\"l\":7,\"p\":0.0},\"401102\":{\"l\":7,\"p\":0.008},\"505006\":{\"l\":7,\"p\":0.217},\"401103\":{\"l\":6,\"p\":0.118},\"2022004\":{\"l\":7,\"p\":0.003},\"501004\":{\"l\":0,\"p\":1.0},\"501001\":{\"l\":0,\"p\":1.0},\"600006\":{\"l\":6,\"p\":0.157},\"501011\":{\"l\":0,\"p\":1.0},\"301106\":{\"l\":0,\"p\":1.0},\"510000\":{\"l\":4,\"p\":0.267},\"2022018\":{\"l\":8,\"p\":0.003}},\"selected\":[203301,2022008,2022004],\"title\":{\"challengeId\":402408,\"level\":3,\"itemId\":40240802}}",
          "championOwned": "true",
          "perks": "{\"perkIds\":[8437,8446,8429,8451,8009,9105,5008,5008,5001],\"perkStyle\":8400,\"perkSubStyle\":8000}",
          "ranked": "{\"isRankedQueue\":true,\"tier\":\"GOLD\",\"rank\":\"I\",\"previousSeasonAchievedTier\":\"GOLD\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":true,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
          "statstones": "{\"statstones\":[]}",
          "summonerBanner": "{\"flag\":{\"level\":\"2\",\"theme\":\"Demacia\"},\"frame\":{\"level\":\"3\"}}",
          "summonerEmotes": "{\"summonerEmoteIds\":[3781,3155,3147,3149,1506,1506,1506,1506,1506]}",
          "summonerTrophy": "null",
          "vintageSkin": "{\"isVintageSkin\":false}",
          "wardSkin": "{\"wardSkinId\":127}"
        },
        "index": 0,
        "lastSelectedSkinIndex": 5,
        "locale": null,
        "minor": false,
        "originalAccountNumber": 0,
        "originalPlatformId": null,
        "partnerId": "",
        "pickMode": 1,
        "pickTurn": 0,
        "profileIconId": 1389,
        "puuid": "ccff17d8-b3a6-5924-bb05-c333f03fbe92",
        "queueRating": 0,
        "rankedTeamGuest": false,
        "selectedPosition": "TOP",
        "selectedRole": "TOP.PRIMARY.TOP.MIDDLE",
        "summonerId": 66655009,
        "summonerInternalName": "xpayne",
        "summonerName": "xPayne",
        "teamOwner": false,
        "teamParticipantId": 8,
        "teamRating": 0,
        "timeAddedToQueue": 1654888945691,
        "timeChampionSelectStart": 1654888951317,
        "timeGameCreated": 1654889181005,
        "timeMatchmakingStart": 1654888945691,
        "voterRating": 0
      },
      {
        "accountId": 34430635,
        "adjustmentFlags": 0,
        "botDifficulty": "NONE",
        "clientInSynch": true,
        "gameCustomization": {
          "GoldenSpatulaClub": "{\"Rewards\":[]}",
          "Regalia": "{\"crest\":{\"displayType\":\"prestige\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
          "challenges": "{\"challenges\":{\"0\":{\"l\":4,\"p\":0.016},\"402503\":{\"l\":3,\"p\":0.111},\"402500\":{\"l\":4,\"p\":0.04},\"203201\":{\"l\":4,\"p\":0.037},\"402403\":{\"l\":3,\"p\":0.065},\"504002\":{\"l\":7,\"p\":0.008},\"203301\":{\"l\":2,\"p\":0.155},\"2022008\":{\"l\":2,\"p\":0.123},\"401102\":{\"l\":9,\"p\":0.001},\"505006\":{\"l\":7,\"p\":0.217},\"401103\":{\"l\":6,\"p\":0.118},\"2022004\":{\"l\":6,\"p\":0.035},\"501004\":{\"l\":7,\"p\":0.042},\"501001\":{\"l\":9,\"p\":0.0},\"600006\":{\"l\":9,\"p\":0.002},\"501011\":{\"l\":7,\"p\":0.003},\"301106\":{\"l\":6,\"p\":0.068},\"510000\":{\"l\":6,\"p\":0.04},\"2022018\":{\"l\":7,\"p\":0.027}},\"selected\":[401102,501001,501011],\"title\":{\"challengeId\":402408,\"level\":3,\"itemId\":40240802}}",
          "championOwned": "true",
          "perks": "{\"perkIds\":[8465,8401,8444,8453,8304,8347,5007,5002,5001],\"perkStyle\":8400,\"perkSubStyle\":8300}",
          "ranked": "{\"isRankedQueue\":true,\"tier\":\"PLATINUM\",\"rank\":\"IV\",\"previousSeasonAchievedTier\":\"PLATINUM\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":true,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
          "statstones": "{\"statstones\":[{\"contentId\":\"1f9d69ad-60d9-40f1-9e38-e06b2985338f\",\"payload\":{\"currentValue\":580,\"isShowcased\":false,\"maxDelta\":51,\"disabled\":false}},{\"contentId\":\"e0549d76-93fd-4b91-94b7-4ab4720affb6\",\"payload\":{\"currentValue\":46,\"isShowcased\":false,\"maxDelta\":5,\"disabled\":false}},{\"contentId\":\"797e2740-0652-4901-9b0e-51307cfdc3e1\",\"payload\":{\"currentValue\":110,\"isShowcased\":false,\"maxDelta\":9,\"disabled\":false}},{\"contentId\":\"26b82420-de16-4b08-b2ea-c0c6e5741f06\",\"payload\":{\"currentValue\":279979,\"isShowcased\":true,\"maxDelta\":18697,\"disabled\":false}},{\"contentId\":\"9bb9a985-a17b-4667-97d1-50c84fa8ba3e\",\"payload\":{\"currentValue\":46,\"isShowcased\":true,\"maxDelta\":6,\"disabled\":false}},{\"contentId\":\"c293b4ac-055d-49b3-99b0-d3a3841a8eb5\",\"payload\":{\"currentValue\":84,\"isShowcased\":true,\"maxDelta\":12,\"disabled\":false}}]}",
          "summonerBanner": "{\"flag\":{\"level\":\"1\",\"theme\":\"EOS2021\"},\"frame\":{\"level\":\"3\"}}",
          "summonerEmotes": "{\"summonerEmoteIds\":[3338,3810,3448,3278,3407,3278,3279,3278,3279]}",
          "summonerTrophy": "null",
          "vintageSkin": "{\"isVintageSkin\":false}",
          "wardSkin": "{\"wardSkinId\":141}"
        },
        "index": 0,
        "lastSelectedSkinIndex": 1,
        "locale": null,
        "minor": false,
        "originalAccountNumber": 0,
        "originalPlatformId": null,
        "partnerId": "",
        "pickMode": 1,
        "pickTurn": 0,
        "profileIconId": 5380,
        "puuid": "f7a7a007-9ce0-5e3f-a058-6387ec699e96",
        "queueRating": 0,
        "rankedTeamGuest": false,
        "selectedPosition": "UTILITY",
        "selectedRole": "UTILITY.PRIMARY.UTILITY.TOP",
        "summonerId": 21154579,
        "summonerInternalName": "rió",
        "summonerName": "Rió",
        "teamOwner": false,
        "teamParticipantId": 9,
        "teamRating": 0,
        "timeAddedToQueue": 1654888935659,
        "timeChampionSelectStart": 1654888951317,
        "timeGameCreated": 1654889181005,
        "timeMatchmakingStart": 1654888935659,
        "voterRating": 0
      },
      {
        "accountId": 2285654189647936,
        "adjustmentFlags": 0,
        "botDifficulty": "NONE",
        "clientInSynch": true,
        "gameCustomization": {
          "GoldenSpatulaClub": "{\"Rewards\":[]}",
          "Regalia": "{\"crest\":{\"displayType\":\"rank\"},\"banner\":{\"displayType\":\"lastSeasonRank\"}}",
          "challenges": "{\"challenges\":{\"0\":{\"l\":3,\"p\":0.264},\"402503\":{\"l\":3,\"p\":0.111},\"402500\":{\"l\":4,\"p\":0.04},\"203201\":{\"l\":1,\"p\":0.156},\"402403\":{\"l\":4,\"p\":0.025},\"504002\":{\"l\":4,\"p\":0.419},\"203301\":{\"l\":2,\"p\":0.155},\"2022008\":{\"l\":6,\"p\":0.01},\"401102\":{\"l\":5,\"p\":0.323},\"505006\":{\"l\":7,\"p\":0.217},\"401103\":{\"l\":5,\"p\":0.31},\"2022004\":{\"l\":5,\"p\":0.101},\"501004\":{\"l\":0,\"p\":1.0},\"501001\":{\"l\":0,\"p\":1.0},\"600006\":{\"l\":6,\"p\":0.157},\"501011\":{\"l\":0,\"p\":1.0},\"301106\":{\"l\":0,\"p\":1.0},\"510000\":{\"l\":3,\"p\":0.45},\"2022018\":{\"l\":7,\"p\":0.001}},\"selected\":[]}",
          "championOwned": "true",
          "perks": "{\"perkIds\":[8128,8139,8138,8135,8210,8226,5005,5008,5002],\"perkStyle\":8100,\"perkSubStyle\":8200}",
          "ranked": "{\"isRankedQueue\":true,\"tier\":\"GOLD\",\"rank\":\"I\",\"rankedRegaliaLevel\":0,\"ratedRating\":0,\"forceRankedRegalia\":true,\"challengerRecall\":false,\"eligibleEarlySurrenderAccomplice\":false}",
          "statstones": "{\"statstones\":[]}",
          "summonerEmotes": "{\"summonerEmoteIds\":[3235,3139,3412,3680,3133,3133,3455,3109,1490]}",
          "summonerTrophy": "null",
          "vintageSkin": "{\"isVintageSkin\":false}",
          "wardSkin": "{\"wardSkinId\":119}"
        },
        "index": 0,
        "lastSelectedSkinIndex": 4,
        "locale": null,
        "minor": false,
        "originalAccountNumber": 0,
        "originalPlatformId": null,
        "partnerId": "",
        "pickMode": 1,
        "pickTurn": 0,
        "profileIconId": 4490,
        "puuid": "7883f7f2-03a7-526f-94e8-330ac51fea9d",
        "queueRating": 0,
        "rankedTeamGuest": false,
        "selectedPosition": "BOTTOM",
        "selectedRole": "BOTTOM.PRIMARY.BOTTOM.MIDDLE",
        "summonerId": 109672522,
        "summonerInternalName": "doublelift",
        "summonerName": "Doublelift",
        "teamOwner": false,
        "teamParticipantId": 10,
        "teamRating": 0,
        "timeAddedToQueue": 1654888918001,
        "timeChampionSelectStart": 1654888951317,
        "timeGameCreated": 1654889181005,
        "timeMatchmakingStart": 1654888918001,
        "voterRating": 0
      }
    ]
  },
  "gameDodge": {
    "dodgeIds": [],
    "phase": "None",
    "state": "Invalid"
  },
  "map": {
    "assets": {
      "champ-select-background-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Shared/sound/music-cs-blindpick-default.ogg",
      "champ-select-flyout-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/champ-select-flyout-background.jpg",
      "champ-select-planning-intro": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/champ-select-planning-intro.jpg",
      "game-select-icon-active": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/game-select-icon-active.png",
      "game-select-icon-active-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/video/game-select-icon-active.webm",
      "game-select-icon-default": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/game-select-icon-default.png",
      "game-select-icon-disabled": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/game-select-icon-disabled.png",
      "game-select-icon-hover": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/game-select-icon-hover.png",
      "game-select-icon-intro-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/video/game-select-icon-intro.webm",
      "gameflow-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/gameflow-background.jpg",
      "gameflow-background-dark": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/gameflow-background-dark.jpg",
      "gameselect-button-hover-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Shared/sound/sfx-gameselect-button-hover.ogg",
      "icon-defeat": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/icon-defeat.png",
      "icon-defeat-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/icon-defeat-v2.png",
      "icon-defeat-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/video/icon-defeat.webm",
      "icon-empty": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/icon-empty.png",
      "icon-hover": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/icon-hover.png",
      "icon-leaver": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/icon-leaver.png",
      "icon-leaver-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/icon-leaver-v2.png",
      "icon-loss-forgiven-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/icon-loss-forgiven-v2.png",
      "icon-v2": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/icon-v2.png",
      "icon-victory": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/icon-victory.png",
      "icon-victory-video": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/video/icon-victory.webm",
      "map-north": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/map-north.png",
      "map-south": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/map-south.png",
      "music-inqueue-loop-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/sound/music-inqueue-loop-summonersrift.ogg",
      "parties-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/parties-background.jpg",
      "postgame-ambience-loop-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/sound/sfx-ambience-loop-summonersrift.ogg",
      "ready-check-background": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/ready-check-background.png",
      "ready-check-background-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/sound/sfx-readycheck-sr-portal.ogg",
      "sfx-ambience-pregame-loop-sound": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/sound/sfx-ambience-loop-summonersrift.ogg",
      "social-icon-leaver": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/social-icon-leaver.png",
      "social-icon-victory": "lol-game-data/assets/content/src/LeagueClient/GameModeAssets/Classic_SRU/img/social-icon-victory.png"
    },
    "categorizedContentBundles": {},
    "description": "The newest and most venerated battleground is known as Summoner's Rift. Traverse down one of three different paths in order to attack your enemy at their weakest point. Work with your allies to siege the enemy base and destroy their Nexus!",
    "gameMode": "CLASSIC",
    "gameModeName": "Summoner's Rift",
    "gameModeShortName": "Summoner's Rift",
    "gameMutator": "",
    "id": 11,
    "isRGM": false,
    "mapStringId": "SR",
    "name": "Summoner's Rift",
    "perPositionDisallowedSummonerSpells": {},
    "perPositionRequiredSummonerSpells": {},
    "platformId": "",
    "platformName": "",
    "properties": {
      "suppressRunesMasteriesPerks": false
    }
  },
  "phase": "InProgress"
}
